var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("ul", { staticClass: "overview__content" }, [
      _c(
        "li",
        {
          class:
            "state-dot state-dot--light-text state-dot--" + _vm.project.status
        },
        [_vm._v(_vm._s(_vm.project.name))]
      ),
      _c("br"),
      _vm._v(" "),
      _c("li", [_vm._v("ID: " + _vm._s(_vm.project.id))]),
      _vm._v(" "),
      _c("li", [_vm._v("Type: " + _vm._s(_vm.project.type))]),
      _vm._v(" "),
      _c("li", [_vm._v("Status: " + _vm._s(_vm.project.status))])
    ]),
    _vm._v(" "),
    _vm.usedFor == "overview" && _vm.projectPermissions.labelprojectfiles
      ? _c(
          "button",
          {
            staticClass: "btn-primary form__item",
            staticStyle: { "min-width": "0px" },
            on: {
              click: function($event) {
                return _vm.work(_vm.project)
              }
            }
          },
          [
            _c("span", { staticClass: "btn-primary__text" }, [
              _vm._v("Start " + _vm._s(_vm.project.type))
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "btn-primary__icon icon icon--tags icon--size-18"
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.usedFor == "overview" && _vm.projectPermissions.editprojects
      ? _c(
          "button",
          {
            staticClass: "btn-tertiary form__item form__item--small-margin-top",
            on: { click: _vm.routeToSingleProjectEdit }
          },
          [_vm._v("\n        Bearbeiten\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.usedFor == "export" && _vm.projectPermissions.exportprojects
      ? _c(
          "button",
          {
            staticClass: "btn-primary form__item",
            staticStyle: { "min-width": "0px" },
            on: {
              click: function($event) {
                return _vm.startExport(_vm.project.id, _vm.project.name)
              }
            }
          },
          [
            _c("span", { staticClass: "btn-primary__text" }, [
              _vm._v("Export")
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass:
                "btn-primary__icon icon icon--arrow-right icon--size-18"
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }