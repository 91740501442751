var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tooltip
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.buttonhover,
                    expression: "buttonhover"
                  }
                ],
                staticClass: "p-b-tooltip",
                attrs: { id: "tooltip" }
              },
              [_vm._t("tooltipText")],
              2
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { type: "button" },
          on: { mouseleave: _vm.hideTooltip, mouseover: _vm.showTooltip }
        },
        [_vm._v("\n        " + _vm._s(_vm.text) + "\n    ")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }