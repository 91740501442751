var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "overview" },
        [
          _vm._l(_vm.loadedProjects, function(project) {
            return _c(
              "div",
              {
                key: project.id,
                staticClass:
                  "overview__item column--12 column--xs-6 column--s-4 column--m-3"
              },
              [
                _c(
                  "div",
                  { staticClass: "card card--full-width card--left" },
                  [
                    _c("ProjectComponent", {
                      attrs: {
                        project: project,
                        "used-for": "overview",
                        "project-permissions": _vm.permissions
                      },
                      on: {
                        "route-single-project": _vm.routeSingleProject,
                        "route-classification": _vm.routeSingleProject
                      },
                      nativeOn: {
                        click: function($event) {
                          _vm.projectUsers = 0
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _vm.permissions.createprojects
            ? _c(
                "button",
                {
                  staticClass:
                    "btn-primary btn-primary--only-icon overview__add-item",
                  on: { click: _vm.routeToNewProject }
                },
                [
                  _c("span", {
                    staticClass:
                      "btn-primary__icon btn-primary--only-icon__icon icon icon--plus icon--size-18"
                  })
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }