<template>
    <section>
        <ul class="overview__content">
            <li :class="'state-dot state-dot--light-text state-dot--'+project.status">{{project.name}}</li><br>
            <li>ID: {{project.id}}</li>
            <li>Type: {{project.type}}</li>
            <li>Status: {{project.status}}</li>
        </ul>
        <button
            v-if="usedFor=='overview' && projectPermissions.labelprojectfiles"
            style="min-width: 0px"
            class="btn-primary form__item"
            @click="work(project)">
            <span class="btn-primary__text">Start {{project.type}}</span>
            <span class="btn-primary__icon icon icon--tags icon--size-18"></span>
        </button>
        <button
            v-if="usedFor=='overview' && projectPermissions.editprojects"
            @click="routeToSingleProjectEdit"
            class="btn-tertiary form__item form__item--small-margin-top">
            Bearbeiten
        </button>
        <button
            v-if="usedFor=='export' && projectPermissions.exportprojects"
            style="min-width: 0px"
            class="btn-primary form__item"
            @click="startExport(project.id, project.name)">
            <span class="btn-primary__text">Export</span>
            <span class="btn-primary__icon icon icon--arrow-right icon--size-18"></span>
        </button>
    </section>
</template>

<script>

    export default {
        props : {
            project: {
                type: Object,
                required: true,
            },
            usedFor: {
                type: String,
                required: true,
            },
            projectPermissions: {
                type: Object,
                required: true
            }
        },
        components: {
        },
        data: () => ({
            updatedProjectName: "",
        }),
        methods: {
            work(_project){
                this.$router.push({
                    name: _project.type+'View',
                    params: {
                    projectId: _project.id,
                    project: _project,
                    }
                })
            },
            routeToSingleProjectEdit() {
                axios.get("/api/projects/" + this.project.id)
                .then(res => {
                    if(res && this.projectPermissions.editprojects){
                        this.$router.push({name: "SingleProject", params:{project: this.project, projectId: this.project.id}})
                    }
                })
            },
            startExport(_projectId, _projectName) {
                axios.get("api/projects/" + _projectId + "/label-data", { responseType: 'arraybuffer'})
                    .then(response => {
                        console.log(response);
                        const blob = new Blob([response.data],{type:'application/zip'});
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.setAttribute('download', _projectName+'.zip');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                })
            }
        }
    }
</script>

<style scoped>
    a {
        color: #5e1c19;
    }
    a:hover {
        color: #955252;
    }
</style>
