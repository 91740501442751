import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
    },
    {
        path: "/companies",
        name: "CompaniesView",
        component: () => import(/* webpackChunkName: "CompaniesView" */"../views/companies/CompaniesView.vue"),
        children: [
            {
                path: ":companyId",
                name: "SingleCompany",
                component: () => import(/* webpackChunkName: "SingleCompany" */"../views/companies/SingleCompany.vue"),
                props: true,

                beforeEnter: (to, from, next) => {
                    if(!to.params.company) {
                        axios.get("/api/companies/" + to.params.companyId).then(res => {
                            to.params.company = res.data;
                        }).finally(() => {
                            next();
                        }).catch(() => {
                            router.push({ name: 'Forbidden' })
                        })
                    }
                    else {
                        next();
                    }
                }
            },
            {
                path: "new",
                name: "AddNewCompany",
                component: () => import(/* webpackChunkName: "AddNewCompany" */"../views/companies/AddNewCompany.vue"),
                props: false,
            }
        ],
    },
    {
        path: "/users",
        name: "UsersView",
        component: () => import(/* webpackChunkName: "UsersView" */"../views/users/UsersView.vue"),
        props: true,
        children: [
            {
                path: ":userId/details",
                name: "SingleUserDetails",
                component: () => import(/* webpackChunkName: "SingleUserDetails" */"../views/users/SingleUserDetails.vue"),
                props: true,

                beforeEnter: (to, from, next) => {
                    if(!to.params.user) {
                        axios.get("/api/users/" + to.params.userId).then(res => {
                            to.params.user = res.data;
                        }).finally(() => {
                            next();
                        })
                        .catch(() => {
                            router.push({ name: 'Forbidden' })
                        })
                    }
                    else {
                        next();
                    }
                }
            },
            {
                path: ":userId/profile",
                name: "SingleUserProfile",
                component: () => import(/* webpackChunkName: "SingleUserProfile" */"../views/users/SingleUserProfile.vue"),
                props: true,

                beforeEnter: (to, from, next) => {
                    axios.get("/api/self")
                        .then(res => {
                            to.params.user = res.data;
                        }).finally(() => {
                            if(to.params.user.id != to.params.userId){
                                router.push({ name: 'Forbidden' })
                            } else {
                                next();
                            }
                        })
                }
            },
            {
                path: "new",
                name: "AddNewUser",
                component: () => import(/* webpackChunkName: "AddNewUser" */"../views/users/AddNewUser.vue"),
                props: false,
            }
        ],
    },
    {
        path: "/export",
        name: "ExportView",
        component: () => import(/* webpackChunkName: "ExportView" */"../views/export/ExportView.vue"),
        props: true,
    },
    {
        path: "/projects",
        name: "ProjectsView",
        component: () => import(/* webpackChunkName: "ProjectsView" */"../views/projects/ProjectsView.vue"),
        props: true,
        children: [
            {
                path: "NewProject",
                name: "NewProject",
                component: () => import(/* webpackChunkName: "NewProject" */"../views/projects/NewProject.vue"),
                props: false,
            },
            {

                path: ":projectId",
                name: "SingleProject",
                component: () => import(/* webpackChunkName: "SingleProject" */"../views/projects/SingleProject.vue"),
                props: true,
                
                beforeEnter: (to, from, next) => {
                    if(!to.params.project) {
                        axios.get("/api/projects/" + to.params.projectId)
                            .then(res => {
                                to.params.project = res.data;
                            })
                            .catch(e => {
                                console.log(e);
                            })
                            .finally(() => {
                                next();
                            })
                    }
                    else {
                        next();
                    }
                }
            },
        ],
    },
    {
        path: "/classification/:projectId",
        name: "classificationView",
        component: () => import(/* webpackChunkName: "classificationView" */"../views/work/ClassificationView.vue"),
        props: true,

        beforeEnter: (to, from, next) => {
            if(!to.params.project) {
                axios.get("/api/projects/" + to.params.projectId)
                    .then(res => {
                        to.params.project = res.data;
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        next();
                    })
            }
            else {
                next();
            }
        }
    },
    {
        path: "/segmentation/:projectId",
        name: "segmentationView",
        component: () => import(/* webpackChunkName: "segmentationView" */"../views/work/SegmentationView.vue"),
        props: true,

        beforeEnter: (to, from, next) => {
            if(!to.params.project) {
                axios.get("/api/projects/" + to.params.projectId)
                    .then(res => {
                        to.params.project = res.data;
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        next();
                    })
            }
            else {
                next();
            }
        }
    },
    {
        path: "/detection/:projectId",
        name: "detectionView",
        component: () => import(/* webpackChunkName: "detectionView" */"../views/work/DetectionView.vue"),
        props: true,

        beforeEnter: (to, from, next) => {
            if(!to.params.project) {
                axios.get("/api/projects/" + to.params.projectId)
                    .then(res => {
                        to.params.project = res.data;
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        next();
                    })
            }
            else {
                next();
            }
        }
    },
    {
        path: "/404",
        alias: "*",
        name: "notFound",
        component: () => import(/* webpackChunkName: "NotFound" */"../views/NotFoundView.vue")
    },
    {
        path: "/403",
        alias: "*",
        name: "Forbidden",
        component: () => import(/* webpackChunkName: "NotFound" */"../views/ForbiddenView.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if(savedPosition) {
            return savedPosition;
        }
        else {
            const position = {};
            if(to.hash) {
                position.selector = to.hash;
                if(to.hash === '#experience') {
                    position.offset = {y: 180};
                }
                if(document.querySelector(to.hash)) {
                    return position;
                }
                return false;
            }
        }
    },
    linkExactActiveClass: "main-navigation__item--active",
    routes
});

export default router;
