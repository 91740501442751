var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        {
          staticClass: "main-navigation",
          class: { "main-navigation--open": _vm.toggle },
          on: {
            click: function($event) {
              _vm.toggle = !_vm.toggle
            }
          }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.permissions.viewanyproject
            ? _c(
                "router-link",
                {
                  staticClass: "main-navigation__item",
                  attrs: { to: { name: "ProjectsView" } },
                  nativeOn: {
                    click: function($event) {
                      _vm.home = false
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass:
                      "main-navigation__icon icon icon--clipboard icon--size-18"
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "main-navigation__text" }, [
                    _vm._v("Projektübersicht")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.permissions.viewanyuser
            ? _c(
                "router-link",
                {
                  staticClass: "main-navigation__item",
                  attrs: { to: { name: "UsersView" } },
                  nativeOn: {
                    click: function($event) {
                      _vm.home = false
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass:
                      "main-navigation__icon icon icon--users icon--size-18"
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "main-navigation__text" }, [
                    _vm._v("Nutzerübersicht")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.permissions.viewanycompany
            ? _c(
                "router-link",
                {
                  staticClass: "main-navigation__item",
                  attrs: { to: { name: "CompaniesView" } },
                  nativeOn: {
                    click: function($event) {
                      _vm.home = false
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass:
                      "main-navigation__icon icon icon--building icon--size-18"
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "main-navigation__text" }, [
                    _vm._v("Unternehmen")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.permissions.exportprojects
            ? _c(
                "router-link",
                {
                  staticClass: "main-navigation__item",
                  attrs: { to: { name: "ExportView" } },
                  nativeOn: {
                    click: function($event) {
                      _vm.home = false
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass:
                      "main-navigation__icon icon icon--file-export icon--size-18"
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "main-navigation__text" }, [
                    _vm._v("Export")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "main-navigation__item main-navigation__item--logout",
              on: { click: _vm.logout }
            },
            [
              _c("span", {
                staticClass:
                  "main-navigation__icon main-navigation__icon--logout icon icon--sign-out icon--size-18"
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "main-navigation__text main-navigation__icon--logout"
                },
                [_vm._v("Abmelden")]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "top-bar" }, [
        _c("div", { staticClass: "top-bar__item top-bar__item--right" }, [
          _c("span", {
            staticClass: "top-bar__icon icon icon--sign-out icon--size-30",
            on: { click: _vm.logout }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "top-bar__item" }, [
          _c("img", {
            staticClass: "top-bar__logo",
            attrs: { src: "/assets/img/PreAI-Logo-Full.png" },
            on: { click: _vm.routeHome }
          })
        ])
      ]),
      _vm._v(" "),
      _vm.home
        ? _c("div", { staticClass: "home" }, [
            _c("h1", { staticClass: "home__heading home__heading--level-1" }, [
              _vm._v("Willkommen bei PreAI")
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("h2", { staticClass: "home__heading home__heading--level-2" }, [
              _vm._v("Das Projektteam:")
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c("p", { staticClass: "home__info" }, [
              _vm._v(
                "Um mehr Informationen zu dieser Anwendung, dem Klassifizierungsprozess und dem Thema KI allgemein zu erhalten besuchen sie unsere Homepage."
              )
            ]),
            _vm._v(" "),
            _vm._m(3)
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "main-navigation__item main-navigation__item--toggle" },
      [
        _c("span", {
          staticClass:
            "main-navigation__icon main-navigation__icon--toggle icon icon--bars icon--size-18"
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "home__info" }, [
      _vm._v(
        'Im Projekt "Vorverarbeitung Bild und Video für KI" wurde diese Anwendung entwickelt, welche Firmen hilft, Bildmaterial für das Training ihrer KIs zu klassifizieren. '
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n        Über die Navigation können Sie die Anwendung selbst entdecken. "
      ),
      _c("br"),
      _vm._v(
        'Tipp: Um den gesamten Umfang der Anwendung zu testen sollten Sie sich unter dem Menüpunkt "Unternehmen" eine eigene Firma inklusive Nutzer anlegen. Die Zugangsdaten erhalten Sie direkt als Mail.\n        '
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overview" }, [
      _c(
        "div",
        {
          staticClass:
            "overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2"
        },
        [
          _c("div", { staticClass: "card card--full-width" }, [
            _c("div", { staticClass: "profile profile--detail" }, [
              _c("img", {
                staticClass:
                  "profile--detail__image profile--detail__image--big",
                attrs: { src: "assets/img/team-egzon-demaj.jpeg" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "profile--detail__desc" }, [
                _vm._v("Egzon Demaj"),
                _c("br"),
                _vm._v("(MIB)"),
                _c("br"),
                _c("br"),
                _vm._v("Vue-Entwicklung"),
                _c("br"),
                _vm._v("Laravel-Entwickung")
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2"
        },
        [
          _c("div", { staticClass: "card card--full-width" }, [
            _c("div", { staticClass: "profile profile--detail" }, [
              _c("img", {
                staticClass:
                  "profile--detail__image profile--detail__image--big",
                attrs: { src: "assets/img/team-fabian-puetz.jpeg" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "profile--detail__desc" }, [
                _vm._v("Fabian Pütz"),
                _c("br"),
                _vm._v("(OMB)"),
                _c("br"),
                _c("br"),
                _vm._v("Konzeption"),
                _c("br"),
                _vm._v("Film")
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2"
        },
        [
          _c("div", { staticClass: "card card--full-width" }, [
            _c("div", { staticClass: "profile profile--detail" }, [
              _c("img", {
                staticClass:
                  "profile--detail__image profile--detail__image--big",
                attrs: { src: "assets/img/team-joshua-keller.jpeg" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "profile--detail__desc" }, [
                _vm._v("Joshua Keller"),
                _c("br"),
                _vm._v("(OMB)"),
                _c("br"),
                _c("br"),
                _vm._v("Design"),
                _c("br"),
                _vm._v("Testing")
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2"
        },
        [
          _c("div", { staticClass: "card card--full-width" }, [
            _c("div", { staticClass: "profile profile--detail" }, [
              _c("img", {
                staticClass:
                  "profile--detail__image profile--detail__image--big",
                attrs: { src: "assets/img/team-nina-hecht.jpg" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "profile--detail__desc" }, [
                _vm._v("Nina Hecht"),
                _c("br"),
                _vm._v("(OMB)"),
                _c("br"),
                _c("br"),
                _vm._v("Laravel-Entwicklung"),
                _c("br"),
                _vm._v("Frontend-Entwicklung")
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2"
        },
        [
          _c("div", { staticClass: "card card--full-width" }, [
            _c("div", { staticClass: "profile profile--detail" }, [
              _c("img", {
                staticClass:
                  "profile--detail__image profile--detail__image--big",
                attrs: { src: "assets/img/team-simon-maier.jpeg" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "profile--detail__desc" }, [
                _vm._v("Simon Maier"),
                _c("br"),
                _vm._v("(OMB)"),
                _c("br"),
                _c("br"),
                _vm._v("Konzeption"),
                _c("br"),
                _vm._v("Vue-Entwicklung")
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2"
        },
        [
          _c("div", { staticClass: "card card--full-width" }, [
            _c("div", { staticClass: "profile profile--detail" }, [
              _c("img", {
                staticClass:
                  "profile--detail__image profile--detail__image--big",
                attrs: { src: "assets/img/team-simon-pirmann.jpg" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "profile--detail__desc" }, [
                _vm._v("Simon Pirmann"),
                _c("br"),
                _vm._v("(MIB)"),
                _c("br"),
                _c("br"),
                _vm._v("Projektleitung"),
                _c("br"),
                _vm._v("Laravel-Entwicklung")
              ])
            ])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "form__item btn-primary btn-primary--only-text",
        attrs: { href: "https://www.preai.de/", target: "_blank" }
      },
      [
        _c(
          "span",
          { staticClass: "btn-primary__text btn-primary--only-text__text" },
          [_vm._v("Homepage")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }